import * as Sentry from '@sentry/react';

// import {
//   getNotificationChannel,
//   getNotificationType,
// } from '../../../utils/constants/entities/notifications';
import { generateNotificationFields } from '../Clients/Dialogs/CreateEditDialog/CreateEditDialog.functions';

export function generateNotificationsObject(t, data) {
  let notifications = generateNotificationFields(t);

  if (!data?.notification) {
    return notifications;
  }

  let parsedNotification = [];

  try {
    parsedNotification = JSON.parse(data.notification);
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        data: data.notification,
      },
    });
  }

  for (let n of parsedNotification) {
    if (
      !n.order_notification_type ||
      !n.order_notification_recipient_type ||
      !n.order_notification_channel
    ) {
      continue;
    }

    const id = `${n.order_notification_type}-${n.order_notification_recipient_type}-${n.order_notification_channel}`;
    let newObj = {};

    if (parseInt(n.order_notification_channel) === 1) {
      newObj.subject = n.subject;
      newObj.body = n.body;
    } else if (parseInt(n.order_notification_channel) === 2) {
      newObj.msg = n.msg;
    }

    notifications[id] = {
      isChecked: true,
      ...newObj,
    };
  }
  return notifications;
}

export function getInitialValues(t, settings) {
  let initialValues = {
    'Adresnica-KontrolaSeriskiBroj': false,
    'Adresnica-PrikaziNovaFormaPosleKreiranje': false,
    'Adresnica-DefaultFreeInputForTheReceiver': false,
    'Company-Address': '',
    'Company-Telephone': '',
    'Company-CEO': '',
    'Company-Name': '',
    'Company-Email': '',
    'Company-VatNumber': '',
    'Company-PersonalNumber': '',
    'Company-BankAccount': '',
    'Company-EmailComplaints': '',
    'Company-Phone': '',
    'Company-Phone2': '',
    'Config-NpoKlientId': 0,
    'Config-FakturaKlientId': 0,
    'Config-NvpKlientId': 0,
    'Config-NppKlientId': 0,
    'Config-PovratenDokumentKlientId': 0,
    'Customer-DefaultPricelistId': 1,
    'Customer-RequiredEmail': false,
    'Customer-RequiredMobileOrPhone': false,
    'Customer-RequiredBankAccount': false,
    'Customer-RequiredEntityNumber': false,
    'Customer-RequiredTaxNumber': false,
    'Customer-RequiredHouseNumber': false,
    'Mail-Notifications': '',
    'Mail-RegistrationAdminApprove': '',
    'Notification-Subscriptions': '[]',
    'SMS-DefaultGateway': '',
    'SMS-NotificationsNumber': '',
    'Web-SaveImportListContent': false,
    'Input-Validation': '[]',
  };

  for (let s in settings?.data) {
    const item = settings.data[s];
    initialValues[`${item.section}-${item.ident}`] = item.value;
  }

  initialValues['notification'] = generateNotificationsObject(
    t,
    initialValues['Notification-Subscriptions']
  );

  return initialValues;
}

export function toApiData(initialData, values, section) {
  let apiData = {};

  for (const obj of initialData.data) {
    const id = initialData.data.find(
      (o) => o.section === section && o.ident === obj.ident
    )?.id;

    // Something unexpected happened
    if (!id) {
      continue;
    }
    debugger;
    if (obj.section === section) {
      if (obj.section === 'Notification' && obj.ident === 'Subscriptions') {
        let notification = [];

        for (let notificationObj in values.notification) {
          if (!values.notification[notificationObj].isChecked) {
            continue;
          }

          const [nType, nrType, nChannel] = notificationObj.split('-');

          notification.push({
            order_notification_type: nType,
            order_notification_recipient_type: nrType,
            order_notification_channel: nChannel,
            ...(parseInt(nChannel) === 1
              ? {
                  subject: values.notification[notificationObj].subject,
                  body: values.notification[notificationObj].body,
                }
              : {
                  msg: values.notification[notificationObj].msg,
                }),
          });
        }

        try {
          apiData[id] = JSON.stringify(notification);
        } catch (e) {
          Sentry.captureException(e, {
            extra: {
              data: notification,
            },
          });
        }
      } else {
        apiData[id] = values[`${section}-${obj.ident}`];
      }
    }
  }

  return apiData;
}
